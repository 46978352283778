<template>
	<div class="wallet_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span>返回</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/shop/physical' }"> 实体卡 </el-breadcrumb-item>
			<el-breadcrumb-item>申请实体卡</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="wallet">
			<div class="w_body">
				<div class="w_left">
					<div class="card1">
						<div class="number">3829 4820 4629 5025</div>
					</div>
				</div>
				<div class="w_right">
					<div class="font">
						<div>
							开启
							<el-input-number
								class="number"
								v-model="value"
								:min="1"
								:max="50"
								size="small"
								controls-position="right"
								@change="handleChange"
							/>
							/50卡片
						</div>
						<img class="icon" src="../../assets/shop/icon.png" alt="" />
					</div>
					<el-slider v-model="value" max="50" />
					<div class="content">
						<div class="item">购卡数量:1</div>
						<div class="item">购卡金额:10USDT</div>
					</div>
					<div class="content">
						<div class="item">首次最低充值:20USDT</div>
						<div class="item">总计支付:30.12USDT</div>
					</div>
					<button class="button_box">购买卡片（30.12USDT)</button>
				</div>
			</div>
			<div class="instructions">
				<div class="title">
					<img src="../../assets/shop/title.png" alt="" class="t_icon" />
					<span>个人信息填写</span>
				</div>
				<el-form label-position="top" :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item label="姓">
						<el-input v-model="formInline.user" placeholder="Approved by" clearable />
					</el-form-item>
					<el-form-item label="名">
						<el-input v-model="formInline.user" placeholder="Approved by" clearable />
					</el-form-item>
					<el-form-item label="手机">
						<el-select v-model="formInline.region" placeholder="Activity zone" clearable>
							<el-option label="Zone one" value="shanghai" />
							<el-option label="Zone two" value="beijing" />
						</el-select>
					</el-form-item>
					<el-form-item label="邮箱">
						<el-input v-model="formInline.user" placeholder="Approved by" clearable />
					</el-form-item>
					<el-form-item label="出生日期">
						<!-- <el-date-picker
							v-model="formInline.date"
							type="date"
							placeholder="Pick a date"
							clearable
						/> -->
						<el-date-picker
							class="datePicker"
							v-model="formInline.date"
							type="date"
							placeholder="Pick a day"
							clearable
						/>
					</el-form-item>
					<el-form-item label="邮政编码">
						<el-input v-model="formInline.user" placeholder="Approved by" clearable />
					</el-form-item>
					<el-form-item label="国家/地区">
						<el-select v-model="formInline.region" placeholder="Activity zone" clearable>
							<el-option label="Zone one" value="shanghai" />
							<el-option label="Zone two" value="beijing" />
						</el-select>
					</el-form-item>
					<el-form-item label="城市">
						<el-input v-model="formInline.user" placeholder="Approved by" clearable />
					</el-form-item>
					<el-form-item label="账单地址">
						<el-input
							class="bigInput"
							v-model="formInline.user"
							placeholder="Approved by"
							clearable
						/>
					</el-form-item>
				</el-form>
			</div>
			<div class="instructions">
				<div class="title">
					<img src="../../assets/shop/title.png" alt="" class="t_icon" />
					<span>ID验证</span>
				</div>
				<el-form-item>
					<div>检查签发国家</div>
					<el-select
						class="up_input"
						v-model="formInline.region"
						placeholder="Activity zone"
						clearable
					>
						<el-option label="Zone one" value="shanghai" />
						<el-option label="Zone two" value="beijing" />
					</el-select>
				</el-form-item>
				<div style="display: flex">
					<div>
						<div class="up">护照照片页</div>
						<el-upload class="up" action="#" list-type="picture-card" :auto-upload="false">
							<el-icon><Plus /></el-icon>
							<template #file="{ file }">
								<div>
									<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span
											class="el-upload-list__item-preview"
											@click="handlePictureCardPreview(file)"
										>
											<el-icon><zoom-in /></el-icon>
										</span>
										<span
											v-if="!disabled"
											class="el-upload-list__item-delete"
											@click="handleDownload(file)"
										>
											<el-icon><Download /></el-icon>
										</span>
										<span
											v-if="!disabled"
											class="el-upload-list__item-delete"
											@click="handleRemove(file)"
										>
											<el-icon><Delete /></el-icon>
										</span>
									</span>
								</div>
							</template>
						</el-upload>
						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" />
						</el-dialog>
					</div>
					<div>
						<div class="up">身份证正反面</div>
						<el-upload class="up" action="#" list-type="picture-card" :auto-upload="false">
							<el-icon><Plus /></el-icon>
							<template #file="{ file }">
								<div>
									<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span
											class="el-upload-list__item-preview"
											@click="handlePictureCardPreview(file)"
										>
											<el-icon><zoom-in /></el-icon>
										</span>
										<span
											v-if="!disabled"
											class="el-upload-list__item-delete"
											@click="handleDownload(file)"
										>
											<el-icon><Download /></el-icon>
										</span>
										<span
											v-if="!disabled"
											class="el-upload-list__item-delete"
											@click="handleRemove(file)"
										>
											<el-icon><Delete /></el-icon>
										</span>
									</span>
								</div>
							</template>
						</el-upload>
						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" />
						</el-dialog>
					</div>
				</div>
			</div>
			<div class="instructions">
				<div class="title">
					<img src="../../assets/shop/title.png" alt="" class="t_icon" />
					<span>购卡说明</span>
				</div>
				<div class="content">
					<div class="c_title">支持消费场景</div>
					<div class="c_body">
						<p>
							Adobe, Aliexpress, Amazon, AWS, ChatGPT, CloudFlare，Facebook,
							GODADDY,MidJourney, Notion, Telegram, Tiktok, Walmart, Wechat/ AliPay,
							MasterCard，PayPal, Grab, ONLYFANS, Patreon, Etsy, Apple.
						</p>
						<p>(暂不支持US Bank, Uber;Binance, OKX等虚拟货币平台进行消费交易， 否则会立刻销卡并冻结)
						</p>
					</div>
					<div class="c_title">手续费</div>
					<div class="c_body">
						<p>充值手续费:1%</p>
						<p>购卡费用:39 USD</p>
					</div>
					<div class="c_title">额度说明</div>
					<div class="c_body">
						<p>1.最低充值金额:30 USD</p>
						<p>2.最高充值金额:100000 USD</p>
						<p>3.最大购卡数量: 5</p>
						<p>4.是否需要首次充值:是</p>
						<p>5.最小首次充值金额:30 USD</p>
					</div>
					<div class="c_title">卡片使用须知</div>
					<div class="c_body">
						<p>
							1.如果发卡方在使用过程中检测到大批量退款、取消、失败或退单等恶意行为，卡片将自动冻结，每次将扣除10
							USD的费用。
						</p>
						<p>2.如果整体交易失败率超过20%，卡片将自动冻结。</p>
						<p>3.如果卡片余额不足，并累计达成3次连续授权失败，卡片将自动注销。</p>
						<p>4.如果卡片被冻结，请联系客服申请解冻。</p>
						<p>5.卡片有效期为3年。</p>
					</div>
					<div class="api">
						对于月交易量超过50万USD或需要API集成的客户，请联系客户经理调整费用。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import QrcodeVue from 'qrcode.vue';
import { ArrowLeftBold, Delete, Download, Plus, ZoomIn } from '@element-plus/icons-vue';
// import { UploadFile } from 'element-plus';

const dialogImageUrl = ref('');
const dialogVisible = ref(false);
const disabled = ref(false);

const handleRemove = (file) => {
	console.log(file);
};

const handlePictureCardPreview = (file) => {
	dialogImageUrl.value = file.url;
	dialogVisible.value = true;
};

const handleDownload = (file) => {
	console.log(file);
};
const router = useRouter();
let state = reactive({
	input1: 'GJDKIOQMME',
	input2: 'https://www.coin.com/zh-hant/assets/coin',
	dialogTableVisible: false,
});
const value = ref('');

const options = [
	{
		value: 'Option1',
		label: 'Option1',
	},
	{
		value: 'Option2',
		label: 'Option2',
	},
	{
		value: 'Option3',
		label: 'Option3',
	},
	{
		value: 'Option4',
		label: 'Option4',
	},
	{
		value: 'Option5',
		label: 'Option5',
	},
];

const formInline = reactive({
	user: '',
	region: '',
	date: '',
});

const onSubmit = () => {
	console.log('submit!');
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.wallet_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 7px;
				}
			}
			.box:hover {
				cursor: pointer;

				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.wallet {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			padding: 44px 67px 44px 27px;
			.w_body {
				// height: 202px;
				// padding: 0 52px;
				display: flex;
				align-items: center;
				.w_left {
					width: 400px;
					height: 253px;
					margin-right: 70px;
					.card1 {
						width: 400px;
						height: 253px;
						background: url('../../assets/shop/shop1.png');
						background-size: 400px 253px;
						background-repeat: no-repeat;
						position: relative;
						.number {
							position: absolute;
							top: 74px;
							left: 32px;
							font-weight: 600;
							font-size: 29px;
							color: #ffffff;
							letter-spacing: 1px;
						}
					}
				}
				.w_right {
					display: flex;
					// align-items: center;
					flex-direction: column;
					.font {
						display: flex;
						align-items: center;
						font-size: 24px;
						color: #333333;
						margin-bottom: 8px;
						font-weight: 600;
						.number {
							width: 80px;
							height: 32px;
						}
						.icon {
							width: 27px;
							margin-left: 27px;
						}
					}
					.content {
						display: flex;
						justify-content: space-between;
						font-weight: 600;
						font-size: 21px;
						color: #333333;
						margin-top: 22px;
						margin-bottom: 20px;
						.item {
							width: 250px;
						}
					}
					div:last-of-type {
						margin-top: 0;
					}
					.button_box {
						margin-top: 7px;
						width: 627px;
						height: 53px;
						background: #0d0d1f;
						border-radius: 5px;
						border: 1px solid #e4e4e4;
						font-size: 21px;
						color: #ffffff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.instructions {
				margin-top: 40px;
				width: 1225px;
				// height: 988px;
				background: #f6f8fc;
				border-radius: 8px;
				padding: 34px 17px 40px 40px;
				.title {
					.t_icon {
						width: 37px;
						margin-right: 17px;
					}
					font-size: 27px;
					color: #333333;
					display: flex;
					font-weight: 600;
					align-items: center;
					margin-bottom: 27px;
				}
				.content {
					display: flex;
					flex-direction: column;
					.c_title {
						font-size: 24px;
						line-height: 33px;
						color: #333333;
						margin-bottom: 6px;
					}
					.c_body {
						font-size: 21px;
						line-height: 33px;
						color: #666666;
						margin-bottom: 36px;
					}
					.api {
						font-size: 24px;
						color: #ff5b00;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.datePicker {
	width: 480px !important;
}
.bigInput {
	width: 1000px !important;
}
.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px !important;
	}
}
.el-slider__bar {
	background-color: #1ece47;
	height: 9px !important;
}
.el-slider__button {
	border-radius: 50%;
	background-color: #1ece47;
	border: 2px solid #1ece47;
}
.el-slider__runway {
	background-color: #dddddd;
	height: 9px !important;
}
.el-form-item__content {
	display: block;
}
.demo-form-inline .el-input {
	--el-input-width: 480px;
}

.demo-form-inline .el-select {
	--el-select-width: 480px;
}
.el-form-item__label {
	color: #333333;
	font-weight: 600;
}
.up_input {
	width: 480px;
}
.el-upload--picture-card {
	--el-upload-picture-card-size: 100px;
}
.el-upload-list--picture-card {
	--el-upload-picture-card-size: 100px;
}
.el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
.up {
	width: 300px;
	ul {
		padding-left: 0 !important;
	}
}
</style>
